import Vue from "vue";

export default Vue.extend({
  methods: {
    formatValue(value: number, rounding: number = 2): string {
      let minimumRounding: number = 1 / Math.pow(10, rounding);
      if (Math.abs(Number(value)) < minimumRounding) {
        return (0.0).toString();
      } else
        return value == null
        ? value
        : value.toLocaleString("en-US", {
          maximumFractionDigits: rounding,
          minimumFractionDigits: rounding,
        });
    },
  },
});
