
import Vue from "vue";

export default Vue.extend({
  name: "InformationDDC",
  props: {
    redPercent: {
      type: Number,
      default: null,
    },
  },
  data: () => ({}),
});
