
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import InformationDDC from "./InformationDDC.vue";
import formatUtil from "../../mixins/roundValues";
import { formatFinancialAmounts, formatTwoDecimal } from "../../utils/format";

export default Vue.extend({
  name: "HemophiliavWDInhibitors",
  components: { InformationDDC },
  mixins: [formatUtil],
  data: () => ({
    data: [],
    bleedingDisorderSelected: [],
    bleedingDisorders: [],
    isLoading: false,
    payload: {
      year: null,
      diseaseType: [] as number[],
    },
  }),
  computed: {
    maxtableHeight() {
      return window.screen.height - window.screen.height * 0.2;
    },
    yearSelected() {
      return this.$store.getters["ddcreport/getYearSelected"].year;
    },
    title() {
      return i18n.t("reports.ddcreports.listReports.hemophiliavWDInhibitors");
    },
    headers() {
      return [
        {
          text: i18n.t("reports.ddcreports.hemophiliavWDInhibitors.n"),
          value: "n",
        },
        {
          text: i18n.t("reports.ddcreports.hemophiliavWDInhibitors.country"),
          value: "country",
        },
        {
          text: i18n.t("reports.ddcreports.hemophiliavWDInhibitors.region"),
          value: "region",
        },
        {
          text: i18n.t("reports.ddcreports.hemophiliavWDInhibitors.surveyYear"),
          value: "surveyYear",
        },
        {
          text: i18n.t("reports.ddcreports.hemophiliavWDInhibitors.dataYear"),
          value: "dataYear",
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.previousSurveyYear"
          ),
          value: "previousSurveyYear",
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.previousDataYear"
          ),
          value: "previousDataYear",
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.current_Hemophilia_A_with_Inhibitors_Total"
          ),
          value: "current_Hemophilia_A_with_Inhibitors_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.previous_Hemophilia_A_with_Inhibitors_Total"
          ),
          value: "previous_Hemophilia_A_with_Inhibitors_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.hemophilia_A_with_Inhibitors_Perc_Diff"
          ),
          value: "hemophilia_A_with_Inhibitors_Perc_Diff",
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.current_Hemophilia_A_New_Inhibitors_Total"
          ),
          value: "current_Hemophilia_A_New_Inhibitors_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.previous_Hemophilia_A_New_Inhibitors_Total"
          ),
          value: "previous_Hemophilia_A_New_Inhibitors_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.hemophilia_A_New_Inhibitors_Perc_Diff"
          ),
          value: "hemophilia_A_New_Inhibitors_Perc_Diff",
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.current_Hemophilia_B_with_Inhibitors_Total"
          ),
          value: "current_Hemophilia_B_with_Inhibitors_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.previous_Hemophilia_B_with_Inhibitors_Total"
          ),
          value: "previous_Hemophilia_B_with_Inhibitors_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.hemophilia_B_with_Inhibitors_Perc_Diff"
          ),
          value: "hemophilia_B_with_Inhibitors_Perc_Diff",
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.current_Hemophilia_B_New_Inhibitors_Total"
          ),
          value: "current_Hemophilia_B_New_Inhibitors_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.previous_Hemophilia_B_New_Inhibitors_Total"
          ),
          value: "previous_Hemophilia_B_New_Inhibitors_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.hemophilia_B_New_Inhibitors_Perc_Diff"
          ),
          value: "hemophilia_B_New_Inhibitors_Perc_Diff",
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.current_vWD_with_Inhibitors_Total"
          ),
          value: "current_VWD_with_Inhibitors_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.previous_vWD_with_Inhibitors_Total"
          ),
          value: "previous_VWD_with_Inhibitors_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.vWD_with_Inhibitors_Perc_Diff"
          ),
          value: "vwD_with_Inhibitors_Perc_Diff",
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.current_vWD_New_Inhibitors_Total"
          ),
          value: "current_VWD_New_Inhibitors_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.previous_vWD_New_Inhibitors_Total"
          ),
          value: "previous_VWD_New_Inhibitors_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.hemophiliavWDInhibitors.vWD_New_Inhibitors_Perc_Diff"
          ),
          value: "vwD_New_Inhibitors_Perc_Diff",
        },
      ];
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.payload.year = this.yearSelected;
      axios
        .get("report/DDC/Inhibitors", { params: { year: this.yearSelected } })
        .then((res: any) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(`report/ddc/Inhibitors/export`, {
          params: { year: this.yearSelected },
          responseType: "blob",
        })
        .then((res) => {
          const fileName =
            `${i18n.t("reports.ddcreports.pagetitle")} -` + this.title;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.yearSelected) {
      this.getData();
    }
  },
  watch: {
    yearSelected() {
      this.getData();
    },
  },
});
